import React from "react";
import "./styles.css";

import cta1 from "../../assets/fone_2.png";
import cta2 from "../../assets/whatsapp.png";
import img1 from "../../assets/forma-pagamento.jpg";
import img2 from "../../assets/frete.jpg";
import img3 from "../../assets/horario-atendimento.jpg";

const Others = () => {
  return (
    <section id="others">
      <div className="other1">
        <div className="banner"></div>
        <div className="container">
          <div className="column1">
            <p>Ligue agora e solicite um orçamento:</p>
            <figure>
              <a href="tel:37191827">
                <img src={cta1} alt="Chamada para ação de Telefone" />
              </a>
            </figure>
          </div>

          <div className="column2">
            <p>Solicite um orçamento via Whatsapp: </p>
            <figure>
              <a
                href="http://wa.me/+5511942255808?text=Gostaria%20de%20comprar%20peças"
                target="_blank"
                rel="noreferrer"
              >
                <img src={cta2} alt="Chamada para ação de Whatsapp" />
              </a>
            </figure>
          </div>
        </div>
      </div>

      <div className="other2">
        <div className="container">
          <figure>
            <img src={img1} alt="Formas de pagamento" />
          </figure>

          <figure>
            <img src={img2} alt="Fretes grátis para todo o Brasil" />
          </figure>

          <figure>
            <img src={img3} alt="Horário de Atendimento" />
          </figure>
        </div>
      </div>
    </section>
  );
};

export default Others;
