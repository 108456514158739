import React from "react";
import "./styles.css";

import logo from "../../assets/logos-rodape.png";

const Footer = () => {
  return (
    <footer id="footer">
      <div className="footer1">
        <div className="container">
          <div className="column1">
            <p>
              <strong>Endereço:</strong> <br />
              Rua Cunha Gago, 854, sala 5 - Pinheiros - SP. <br />
              <strong>CEP:</strong> 05421001 <br />
              <strong>Telefone:</strong> (11) 94225-5808
            </p>

            <p>
              <strong>CNPJ:</strong> 44.528.828.0001-92 <br />
              Razão social: Bl. Berenstein Comércio.
            </p>
          </div>

          <div className="column2">
            <figure>
              <img src={logo} alt="Logo" />
            </figure>
          </div>
        </div>
      </div>

      <div className="footer2">
        <div className="container">
          <p>L7 Comércio de peças - Todos os direitos reservados.</p>

          <p>
            Desenvolvido por{" "}
            <a href="https://pluspage.com.br" target="_blank" rel="noreferrer">
              Pluspage.com.br
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
