import React from "react";
import "./styles.css";

import img from "../../assets/fone_2.png";

const Buy = () => {
  return (
    <main id="buy">
      <div className="container">
        <figure>
          <a href="tel:37191827">
            <img src={img} alt="Chamada para ação" />
          </a>
        </figure>
      </div>
    </main>
  );
};

export default Buy;
