import React from "react";
import "./styles.css";

import img1 from "../../assets/componentes-motor.jpg";
import img2 from "../../assets/farois-xenno.jpg";
import img3 from "../../assets/componente-suspensao.jpg";
import img4 from "../../assets/componente-freios.jpg";

import card1 from "../../assets/card1.jpg";
import card2 from "../../assets/card2.jpg";
import card3 from "../../assets/card3.jpg";

import cta from "../../assets/call_orcamento.png";

const Content = () => {
  return (
    <section id="content">
      <div className="content1">
        <div className="container">
          <h2 className="title">TUDO PARA SEU HYUNDAI</h2>

          <div className="gallery">
            <figure>
              <img src={img1} alt="Componentes para Motores" />
            </figure>
            <figure>
              <img src={img2} alt="Faróis de Xenon" />
            </figure>
            <figure>
              <img src={img3} alt="Componente da Suspensão" />
            </figure>
            <figure>
              <img src={img4} alt="Componentes dos Freios" />
            </figure>
          </div>
        </div>
      </div>

      <div className="content2">
        <div className="container">
          <h2 className="title">
            PEÇAS NOVAS <span>|</span> ORIGINAIS <span>|</span> IMPORTADAS
          </h2>

          <div className="cards">
            <div>
              <figure>
                <img src={card1} alt="Card 1" />
              </figure>

              <figure className="cta">
                <a
                  href="http://wa.me/+5511942255808?text=Gostaria%20de%20comprar%20peças"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={cta} alt="Chamada para ação" className="cta-img" />
                </a>
              </figure>
            </div>

            <div>
              <figure>
                <img src={card2} alt="Card 2" />
              </figure>

              <figure className="cta">
                <a
                  href="http://wa.me/+5511942255808?text=Gostaria%20de%20comprar%20peças"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={cta} alt="Chamada para ação" className="cta-img" />
                </a>
              </figure>
            </div>

            <div>
              <figure>
                <img src={card3} alt="Card 3" />
              </figure>

              <figure className="cta">
                <a
                  href="http://wa.me/+5511942255808?text=Gostaria%20de%20comprar%20peças"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={cta} alt="Chamada para ação" className="cta-img" />
                </a>
              </figure>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Content;
