import React from "react";
import "./styles.css";

import Logo from "../../assets/logos-topo.png";
import wpp1 from "../../assets/fone_1.png";

const Header = () => {
  return (
    <header>
      <div id="header">
        <div className="container">
          <figure>
            <img src={Logo} alt="Logo" />
          </figure>
        </div>
      </div>

      <div id="cta">
        <div className="container">
          <figure>
            <a href="tel:37191827">
              <img src={wpp1} alt="Número de telefone 1: (11) 3719-1827" />
            </a>
          </figure>
        </div>
      </div>
    </header>
  );
};

export default Header;
